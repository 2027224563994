import React, { useState, useEffect, forwardRef, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { v4 as uuidv4 } from 'uuid';

import ModalStore from '../stores/ModalStore';
import ToastStore from '../stores/ToastStore';
import { TIMEZONES } from '../conf/TimeZones';
import Utils from '../helpers/Utils';
import { GAMES, MESSAGES, URLS } from '../conf/MainConf';
import GameAPI from '../services/GameAPI';
import ModalMessage from './common/ModalMessage';
import GlobalStore from '../stores/GlobalStore';

const BtnCalendar = forwardRef(({ value, onClick, selectedDate }, ref) => {
  return (
    <button className="wrapper" onClick={ onClick } ref={ ref }>
      <span>{ selectedDate.format(`${GAMES.DATE_FORMAT} ${GAMES.HOUR_FORMAT}`) }</span>
    </button>
  );
});

const timezonesSelect = TIMEZONES.map((tz, index) => {
  return {
    id: index,
    label: tz.replace(':', ''),
    value: parseInt(tz.substr(3, 3), 10)
  }
});

const getTimeZoneId = (tzOffset) => {
  let timezonesSelectDefaultId = 10; // UTC +0000
  if (tzOffset) {
    timezonesSelectDefaultId = timezonesSelect.find((tzObj) => {
      return tzObj.value === tzOffset * -1;
    });
    if (timezonesSelectDefaultId) {
      timezonesSelectDefaultId = timezonesSelectDefaultId.id;
    }
  }
  return timezonesSelectDefaultId;
};

// Inner helper
const getDateFormatted = (mmtDate, tz) => {
  return `${mmtDate.format(GAMES.DATE_RFC_FORMAT)} ${timezonesSelect[tz].label.replace('GMT', '')}`;
};


const Game = ({ isEdit, isDelete, isCreate, isReadOnly }) => {
  const { id } = useParams();
  const history = useHistory();
  const datePickerEl = useRef(null);
  const [ gameData, setGameData ] = useState(null);
  const [ timezoneValue, setTimeZoneValue ] = useState(getTimeZoneId(Utils.getTimezone()));
  let gameStatus = gameData ? Utils.getGameStatus(getDateFormatted(gameData.date, timezoneValue)) : GAMES.GAME_STATUS.COMING;
  const cannotUpdate = (isEdit === true || isCreate === true) && gameData !== null && (gameStatus === GAMES.GAME_STATUS.STARTED || gameStatus === GAMES.GAME_STATUS.ENDED);
  
  // Handle input change
  const onInputChange = (evt) => {
    if (evt.target.name) {
      setGameData((oldData) => {
        const current = { ...oldData };
        if (evt.target.name === 'name') {
          current.name = evt.target.value;
        }
        if (evt.target.name.indexOf('teamName') !== -1) {
          const teamCode = evt.target.name.split('_')[1];
          for (const team of current.teams) {
            if (team.code === teamCode) {
              if (evt.target.value !== null && evt.target.value !== undefined && evt.target.value.indexOf('-') === -1) {
                team.name = evt.target.value;
              }
              break;
            }
          }
        }
        if (evt.target.name.indexOf('guest') !== -1) {
          const teamCode = evt.target.name.split('_')[1];
          const index = parseInt(evt.target.name.split('_')[2]);
          for (const team of current.teams) {
            if (team.code === teamCode) {
              team.guests[index].email = evt.target.value;
              break;
            }
          }
        }
        
        return current;
      });
    }
  };
  
  // Handle date change
  const onDateChanged = (date) => {
    if (datePickerEl) {
      datePickerEl.current.setOpen(false);
    }
    setGameData((oldData) => {
      return { ...oldData, date: moment(new Date(date)) };
    });
  };
  
  // Handle time zone selection
  const onTimezoneChanged = (evt) => {
    setTimeZoneValue(evt.target.value);
  };
  
  // Check form
  const checkForm = () => {
    let isFormOk = true;
    let hasMandatory = false;
    let hasInvalidEmail = false;
    let hasInvalidDate = false;
    let hasSameTeamName = false;
    let tmpTeamName = null;
    const currentGameData = { ...gameData };
    gameStatus = Utils.getGameStatus(getDateFormatted(currentGameData.date, timezoneValue));
    
    // Check name
    currentGameData.nameFormState = true;
    if (currentGameData.name.trim() === '') {
      currentGameData.nameFormState = false;
      isFormOk = false;
      hasMandatory = true;
    }
    
    // Check date
    currentGameData.dateFormState = true;
    if (gameStatus === GAMES.GAME_STATUS.STARTED || gameStatus === GAMES.GAME_STATUS.ENDED) {
      currentGameData.dateFormState = false;
      hasInvalidDate = true;
      isFormOk = false;
    }
    
    // Check teams
    for (const team of currentGameData.teams) {
      const tName = team.name.trim();
      team.nameFormState = true;
      if (tName === '') {
        team.nameFormState = false;
        isFormOk = false;
        hasMandatory = true;
      } else {
        console.log('tmpTeamName: ', tmpTeamName);
        console.log('tName: ', tName);
        if (tmpTeamName === tName) {
          hasSameTeamName = true;
        }
        tmpTeamName = tName;
      }
    
      // Check guests email
      for (const guest of team.guests) {
        guest.emailValidationState = true;
        if (guest.email.trim() !== '') {
          if (!Utils.isEmailValid(guest.email)) {
            guest.emailValidationState = false;
            hasInvalidEmail = true;
            isFormOk = false;
          }
        }
      }
    }
  
    // Toast error
    let msg = 'Please ';
    const msgArr = [];
    if (hasMandatory) {
      msgArr.push(MESSAGES.FORM_REQUIRED);
    }
    
    if (hasInvalidDate) {
      msgArr.push(MESSAGES.FORM_INVALID_DATE);
    }
    
    if (hasInvalidEmail) {
      msgArr.push(MESSAGES.FORM_INVALID_EMAIL);
    }
    
    if (!isFormOk) {
      for (let i = 0; i < msgArr.length; i++) {
        msg += `${(msgArr.length > 1 && i === msgArr.length - 1 ? ' and ' : '')}${msgArr[i]}${msgArr.length > 1 && i !== msgArr.length - 1 ? ', ' : ''}`;
      }
      ToastStore.setCurrentMsg(msg, true);
    } else {
      if ( hasSameTeamName ){
        isFormOk = false;
        ToastStore.setCurrentMsg(MESSAGES.FORM_UNIQ_TEAM_NAME, true);
      }
    }
    setGameData(currentGameData);
    
    return isFormOk;
  };
  
  // On validate/save data
  const onValidateForm = () => {
    if (checkForm()) {
      // Sanitize payload before send
      const currentGameData = _.cloneDeep(gameData);
      delete currentGameData.nameFormState;
      currentGameData.name = currentGameData.name.toLowerCase();
      
      // Date: convert to this format: Thu, 17 Dec 2020 21:22:23 +0300
      currentGameData.date = getDateFormatted(currentGameData.date, timezoneValue);
      
      // Teams
      // Check teams
      for (const team of currentGameData.teams) {
        delete team.nameFormState;
        delete team.keyRender;
        team.name = team.name.toLowerCase();
  
        team.guests = team.guests.filter((guest) => {
          return guest.email.trim() !== '';
        }).map((g) => {
          return g.email.toLowerCase();
        });
        for (const guest of team.guests) {
          delete guest.emailValidationState;
          delete guest.keyRender;
          delete guest.id;
        }
      }
      
      // console.log('currentGameData -> ', currentGameData);
      if (isCreate) {
        GameAPI.createGame(currentGameData, () => {
          history.push(URLS.GAMES_LIST);
        })
      }
      if (isEdit) {
        GameAPI.updateGame(currentGameData);
      }
    }
  };
  
  // On cancel
  const onCancel = () => {
    history.push(URLS.GAMES_LIST);
  };
  
  // On add team
  const onAddTeam = () => {
    setGameData((oldData) => {
      const currentData = _.cloneDeep(oldData);
      currentData.teams.push(Utils.getNewTeam());
      return currentData;
    });
  };
  
  // Delete game
  const deleteGame = () => {
    GameAPI.deleteGame({ code: id.toLowerCase() }, () => {
      history.replace(URLS.GAMES_LIST);
    })
  };
  /*
  console.log('isCreate: ', isCreate);
  console.log('isDelete: ', isDelete);
  console.log('isEdit: ', isEdit);
  console.log('isReadOnly: ', isReadOnly);
  console.log('*************************');
  */
  useEffect(() => {
    if (isCreate) {
      // Get server time
      GlobalStore.setLoadingState(true);
      GameAPI.getServerTime().finally(() => {
        setGameData(Utils.getNewGame());
        setTimeZoneValue(getTimeZoneId(Utils.getTimezone()));
        GlobalStore.setLoadingState(false);
      })
    } else {
      if (id && id.trim() !== '') {
        GameAPI.getGameDetails({ code: id }, (data) => {
          // Prepare data
          const currentData = { ...data };
          currentData.nameFormState = true;
          currentData.date = Utils.parseToMomentDate(currentData.date);
          
          // Extract time zone
          const timeZone = currentData.date.utcOffset() / 60 * -1;
          
          if (currentData.teams && currentData.teams.length > 0) {
            for (const team of currentData.teams) {
              team.nameFormState = true;
              team.keyRender = uuidv4();
  
              const guests = team.guests ? Object.values(team.guests) : [];
              team.guests = Utils.getGuests(guests);
            }
          }
          
          // Update gameData
          setGameData(currentData);
          
          // Update GMT
          setTimeZoneValue(getTimeZoneId(timeZone));
          
          // console.log('GAME DETAILS --> ', currentData, ',timeZone: ', timeZone);
          // SHOW MODAL IF DELETE
          if (isDelete) {
            ModalStore.showModal({
              content: <ModalMessage message={ MESSAGES.DELETE } />,
              cancelLabel: 'CANCEL',
              validateLabel: 'CONFIRM DELETE',
              onValidate: () => { deleteGame(); }
            });
          }
        })
      }
    }
  }, [isReadOnly, isEdit, isCreate, isDelete]);
  
  return (
    <section className="inner-content game-container">
      {
        !isDelete && !isReadOnly && (
          <div className="form-wrapper">
            {
              gameData && (
                <div className="form">
                  <div className="form-line">
                    <div className="field-label name">Game name *</div>
                    <div className={ !gameData.nameFormState ? 'field-value name error-field' : 'field-value name' }>
                      <input name="name" type="text" onChange={ onInputChange } value={ gameData.name } maxLength="50"/>
                    </div>
                  </div>
                  <div className="form-line">
                    <div className="field-label date">Date</div>
                    <div className="field-value input-date-select">
                      <DatePicker
                        ref={ datePickerEl }
                        selected={ gameData.date.toDate() }
                        onChange={ onDateChanged }
                        closeOnScroll={ true }
                        showPopperArrow={ false }
                        shouldCloseOnSelect={ true }
                        popperPlacement="bottom"
                        popperModifiers={{
                          flip: {
                            behavior: ["bottom"] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                            enabled: false // turn off since needs preventOverflow to be enabled
                          }
                        }}
                        showTimeSelect
                        timeFormat="hh:mm aa"
                        fixedHeight
                        timeIntervals={ 1 }
                        customInput={ <BtnCalendar selectedDate={ gameData.date } /> }
                      />
                    </div>
                  </div>
                  <div className="form-line">
                    <div className="field-label timezone">TIME ZONE</div>
                    <div className="field-value timezone-value">
                      <select value={ timezoneValue } onChange={ onTimezoneChanged }>
                        {
                          timezonesSelect.map((tzItem) => {
                            return <option value={ tzItem.id } key={ uuidv4() }> { tzItem.label }</option>;
                          })
                        }
                      </select>
                    </div>
                  </div>
                  {
                    gameData.teams.map((teamObj) => {
                      return (
                        <div className="team-wrapper" key={ `${teamObj.keyRender}-${teamObj.code}` }>
                          <div className="form-line team-name-line" key={ teamObj.keyRender }>
                            <div className="field-label team-name">TEAM NAME * </div>
                            <div className={ !teamObj.nameFormState ? 'field-value team-name-value error-field' : 'field-value team-name-value' }>
                              <input name={ `teamName_${teamObj.code}` } type="text" onChange={ onInputChange } value={ teamObj.name } maxLength="15"/>
                            </div>
                          </div>
                          {
                            teamObj.guests.map((guestObj, index) => {
                              return (
                                <div className="form-line guest-line" key={ guestObj.keyRender }>
                                  <div className="field-label guest-email">{ `EMAIL PLAYER ${index + 1}` }</div>
                                  <div className={ !guestObj.emailValidationState ? 'field-value guest-email-value error-field' : 'field-value guest-email-value' }>
                                    <input name={ `guest_${teamObj.code}_${guestObj.id}` } type="text" onChange={ onInputChange } value={ guestObj.email }/>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                  {
                    gameData.teams.length < GAMES.MAX_TEAM && (
                      <div className="form-line">
                        <div className="field-label timezone">
                          <button className="btn-form btn-add-team" onClick={ onAddTeam }>{ `+ ADD AN OTHER TEAM ${gameData.teams.length + 1}/${GAMES.MAX_TEAM}` }</button>
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        )
      }
      {
        (isDelete || isReadOnly) && (
          <div className="form-wrapper">
            {
              gameData && (
                <div className="mode-view-wrapper">
                  <section className="game-block">
                    <div className="view-line">
                      <div className="label">GAME NAME : </div>
                      <div className="value game-name-value">{ gameData.name }</div>
                    </div>
                    <div className="view-line">
                      <div className="label">DATE : </div>
                      <div className="value">{ gameData.date.format(GAMES.DATE_FORMAT) }</div>
                    </div>
                    <div className="view-line">
                      <div className="label">{ `HOUR (${timezonesSelect[timezoneValue].label})`} : </div>
                      <div className="value">{ `${gameData.date.format(GAMES.HOUR_FORMAT)} The game will end at ${gameData.date.clone().add(GAMES.GAME_DURATION, 'm').format(GAMES.HOUR_FORMAT)}` }</div>
                    </div>
                  </section>
                  {
                    gameData.teams.map((teamObj) => {
                      return (
                        <section className="game-team-block" key={ uuidv4() }>
                          <div className="view-line">
                            <div className="label">TEAM NAME : </div>
                            <div className="value team-name-value">{ teamObj.name }</div>
                          </div>
                          <div className="view-line">
                            <div className="label">TEAM CODE : </div>
                            <div className="value team-code-value">{ teamObj.code }</div>
                          </div>
                          {
                            teamObj.guests.filter((item) => {
                              return item.email !== '' && item.email !== undefined && item.email !== null;
                            }).map((guestObj, index) => {
                              return (
                                <div className="view-line" key={ uuidv4() }>
                                  <div className="label guest-label">{ `EMAIL PLAYER ${index + 1}` } : </div>
                                  <div className="value guest-value">{ guestObj.email }</div>
                                </div>
                              );
                            })
                          }
                        </section>
                      );
                    })
                  }
                </div>
              )
            }
          </div>
        )
      }
      {
        (!isReadOnly && !isDelete && gameData) && (
          <div className="actions-wrapper">
            <button className={ cannotUpdate ? 'btn-form btn-validate btn-disabled' : 'btn-form btn-validate' } onClick={ onValidateForm } disabled={ cannotUpdate }>{ isCreate ? 'CREATE' : 'SAVE' }</button>
            <button className="btn-form btn-cancel" onClick={ onCancel }>CANCEL</button>
          </div>
        )
      }
    </section>
  );
};

export default Game;