import React from 'react';
import UserApi from '../../services/UserApi';
import ArrowLeft from '../../assets/images/icon-arrow-back.svg';

const TopBar = ({ onCreateGame, onLogOut, create, onBack }) => {
  const onLogout = () => {
    if (onLogOut) {
      onLogOut();
    }
    UserApi.logout();
  };
  
  const onNewGame = () => {
    if (onCreateGame) {
      onCreateGame();
    }
  };
  
  return (
    <div className="top-bar">
      {
        !create && <button className="new-game" onClick={ onNewGame }>NEW GAME</button>
      }
      <button className="log-out" onClick={ onLogout }>LOGOUT</button>
      {
        onBack !== undefined && (
          <button className="btn-back" onClick={ onBack }>
            <img src={ ArrowLeft } alt="" />
            <span>BACK</span>
          </button>
        )
      }
    </div>
  );
};

export default TopBar;