import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './styles/main.scss';
import GlobalStore from './stores/GlobalStore';
import App from './components/App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App globalStore={ GlobalStore } />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('main-app')
);