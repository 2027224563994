import { makeAutoObservable, toJS} from 'mobx';

class GlobalStore {
  isLoading = false;
  currentPage = null;
  targetURL = null;
  
  constructor() {
    makeAutoObservable(this, {
      targetURL: false
    });
  }
  
  setLoadingState = (state) => {
    this.isLoading = state;
  };
  
  setCurrentPage = (pageObj) => {
    this.currentPage = pageObj;
  }
}

export default new GlobalStore();