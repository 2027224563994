import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { GAMES } from '../conf/MainConf';
import ServerTime from '../stores/ServerTime';

const alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];

class Utils {
  
  constructor() {
    /**
     * Check mail
     * */
    this.isEmailValid = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    
    /**
     * Get timezone in hour
     * */
    this.getTimezone = () => {
      return new Date().getTimezoneOffset() / 60;
    };
    
    /**
     * Contruct a moment date from a date string in Mon Dec 08 2020 12:45:00 GMT+0100 format
     * @param { String } dateStr : String
     * @return { Object }
     * */
    this.parseToMomentDate = (dateStr) => {
      const dateArr = dateStr.split(' ');
      try {
        return moment(dateStr).utcOffset(dateArr[5], false);
      } catch (e) {
        return null;
      }
      
    };
    
    /**
     * Generate a random game code
     * @return { String }
     * */
    this.getGameCode = () => {
      const base = [...alphabet, ...Array.from({length: 10}, (v, i) => i)];
      const result = [];
      for (let i = 0; i < 6; i++) {
        result.push(base[Math.floor(Math.random() * base.length)]);
      }
      return result.join('');
    };
    
    /**
     * Generate a random team code
     * @return { String }
     * */
    this.getTeamCode = () => {
      const letters = ((allLetters) => {
        const result = [];
        for (let i = 0; i < 3; i++) {
          result.push(allLetters[Math.floor(Math.random() * allLetters.length)]);
        }
        return result;
      })(alphabet);
  
      return `${letters.join('')}${Math.floor(Math.random() * 900) + 100}`;
    };
    
    /**
     * Generate guests
     * @param { Array } emails: Array of emails
     * @return { Array } arrays of guests with id 0 - GAMES.MAX_GUEST
     * */
    this.getGuests = (emails) => {
      return Array.from({length: GAMES.MAX_GUEST}, (v, i) => i).map((value) => {
        return {
          keyRender: uuidv4(),
          id: value,
          email: (emails && emails[value]) || '',
          emailValidationState: true
        }
      });
    };
  
    /**
     * Generate a fresh new team
     * */
    this.getNewTeam = () => {
      return {
        keyRender: uuidv4(),
        name: '',
        nameFormState: true,
        code: this.getTeamCode(),
        guests: this.getGuests([])
      }
    };
    
    /**
     * Generate a fresh new game
     * @return { Object }
     * */
    this.getNewGame = () => {
      return {
        name: '',
        nameFormState: true,
        date: moment().add(5, 'm'),
        dateFormState: true,
        code: this.getGameCode(),
        teams: [this.getNewTeam()]
      }
    };
    
    /**
     * Return game status according to it's starting time
     * @param { String } gDate
     * @return { Number }
     * */
    this.getGameStatus = (gDate) => {
      const gameStartDate = new Date(gDate);
      const serverTime = moment(ServerTime.time);
      const beforeStartUp = moment(gameStartDate).subtract(GAMES.GAME_WAIT_DURATION, 'm');
      const gameDate = moment(gameStartDate);
      const endTime= moment(gameStartDate).add(GAMES.GAME_DURATION, 'm');
  
      /* -1: before start-up, 0: start-up, 1: started, 2: ended */
      let status = GAMES.GAME_STATUS.COMING;
      let duration = Math.abs(serverTime.diff(gameDate, 's'));
  
      // Is game not started
      if (serverTime.isBefore(gameDate, 's')) {
        // Is game not about to start in <this.conf.gameWaitDuration>
        if (serverTime.isSameOrAfter(beforeStartUp, 's')) {
          status = GAMES.GAME_STATUS.TO_START;
        }
      } else {
        status = GAMES.GAME_STATUS.STARTED;
        duration = endTime.diff(serverTime, 's');
    
        // Check game ended
        if (serverTime.isSameOrAfter(endTime, 's')) {
          status = GAMES.GAME_STATUS.ENDED;
          duration = 0;
        }
      }
      // console.log('getGameStatus > status: ', status);
      return status;
    }
  }
}

export default new Utils();