import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, useHistory } from "react-router-dom";
import { PAGES, URLS } from '../conf/MainConf';
import UserStore from '../stores/UserStore';
import ModalStore from '../stores/ModalStore';
import ToastStore from '../stores/ToastStore';
import UserApi from '../services/UserApi';
import PageWrapper from './common/PageWrapper';
import Login from './Login';
import LoaderSpinner from './common/LoadSpinner';
import Game from './Game';
import GameList from './GameList';
import ModalWrapper from './common/ModalWrapper';
import Toast from './common/Toast';

const App = observer(({ globalStore }) => {
  const [didUserCheck, setUserCheck] = useState(false);
  const history = useHistory();
  const getPage = (comp, props) => {
    return (
      <PageWrapper userStore={ UserStore } { ...props }>
        { comp }
      </PageWrapper>
    )
  };
  
  const onBack = () => {
    history.push(URLS.GAMES_LIST);
  };
  
  // On mounted we check if we have user
  useEffect(() => {
    UserApi.observeAuthState((result) => {
      setUserCheck(true);
    });
  }, []);
  
  return (
    <React.Fragment>
      {
        didUserCheck && (
          <Switch>
            <Route path={ URLS.LOGIN }>
              <Login />
            </Route>
            <Route path={ URLS.GAME_CREATE } exact={ true } >
              { getPage(<Game isCreate={ true }/>, { title: PAGES.GAME_CREATE.title, create: true }) }
            </Route>
            <Route path={ URLS.GAME_VIEW } exact={ true } >
              { getPage(<Game isReadOnly={ true }/>, { title: PAGES.GAME_VIEW.title, onBack }) }
            </Route>
            <Route path={ URLS.GAME_DELETE } exact={ true } >
              { getPage(<Game isDelete={ true } />, { title: PAGES.GAME_DELETE.title, onBack }) }
            </Route>
            <Route path={ URLS.GAME_EDIT }  exact={ true } >
              { getPage(<Game isEdit={ true } />, { title: PAGES.GAME_EDIT.title, onBack }) }
            </Route>
            <Route path="/">
              { getPage(<GameList />, { title: PAGES.GAMES_LIST.title }) }
            </Route>
          </Switch>
        )
      }
      { globalStore.isLoading && <LoaderSpinner /> }
      <ModalWrapper modalStore={ ModalStore }/>
      <Toast toastStore={ ToastStore } />
    </React.Fragment>
  );
});

export default App;
