export const TIMEZONES = [
  'GMT-11:00',
  'GMT-10:00',
  'GMT-08:00',
  'GMT-07:00',
  'GMT-06:00',
  'GMT-05:00',
  'GMT-04:00',
  'GMT-03:00',
  'GMT-02:00',
  'GMT-01:00',
  'GMT+00:00',
  'GMT+01:00',
  'GMT+02:00',
  'GMT+03:00',
  'GMT+04:00',
  'GMT+05:00',
  'GMT+06:00',
  'GMT+07:00',
  'GMT+08:00',
  'GMT+09:00',
  'GMT+10:00',
  'GMT+11:00',
  'GMT+12:00',
  'GMT+13:00',
  'GMT+14:00'
];