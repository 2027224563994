import { FirebaseDB, FirebaseFunctions } from './FirebaseAPI';
import { ERROR_CODES, MESSAGES } from '../conf/MainConf';
import ServerTime from '../stores/ServerTime';
import UserApi from './UserApi';
import GlobalStore from '../stores/GlobalStore';
import ToastStore from '../stores/ToastStore';

class GameAPI {
  constructor() {
    const serverTimeFunc = FirebaseFunctions.httpsCallable('getServerTime');
    const getGamesFunc = FirebaseFunctions.httpsCallable('getGames');
    const getGameDetailsFunc = FirebaseFunctions.httpsCallable('getGameDetails');
    const createGameFunc = FirebaseFunctions.httpsCallable('createGame');
    const updateGameFunc = FirebaseFunctions.httpsCallable('updateGame');
    const deleteGameFunc = FirebaseFunctions.httpsCallable('deleteGame');
  
    /**
     * Get server date
     * */
    this.getServerTime = () => {
      return new Promise((resolve, reject) => {
        serverTimeFunc().then((response) => {
          ServerTime.setTime(response.data);
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        });
      });
    };
    
    /**
     * Retrieve all games
     * */
    this.getGames = (onSuccess = () => {}, onError = () => {}) => {
      GlobalStore.setLoadingState(true);
      getGamesFunc().then((response) => {
        if (response.data.code && response.data.code === ERROR_CODES.PERMISSION_DENIED) {
          UserApi.logout();
        } else {
          ServerTime.setTime(response.data.serverTime);
          onSuccess(response.data.games);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
  
    /**
     * Get game details
     * */
    this.getGameDetails = (payload, onSuccess = () => {}, onError = () => {}) => {
      GlobalStore.setLoadingState(true);
      getGameDetailsFunc(payload).then((response) => {
        if (response.data.httpErrorCode) {
          if (response.data.code === ERROR_CODES.PERMISSION_DENIED) {
            UserApi.logout();
          }
          const msg = (response.data.message ||  response.data.details) || MESSAGES.INTERNAL_ERROR;
          ToastStore.setCurrentMsg(msg, true);
        } else {
          try {
            ServerTime.setTime(response.data.serverTime);
            onSuccess(response.data);
          } catch (e) {
            console.error('response --> ', e);
          }
        }
      }).catch((errorObj) => {
        ToastStore.setCurrentMsg(MESSAGES.INTERNAL_ERROR, true);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
    
    /**
     * Create game
     * */
    this.createGame = (payload, onSuccess = () => {}, onError = () => {}) => {
      GlobalStore.setLoadingState(true);
      createGameFunc(payload).then((response) => {
        if (response.data.code) {
          if (response.data.code === ERROR_CODES.PERMISSION_DENIED) {
            UserApi.logout();
          }
          const msg = response.data.message || MESSAGES.INTERNAL_ERROR;
          ToastStore.setCurrentMsg(msg, true);
        } else {
          ServerTime.setTime(response.data.serverTime);
          onSuccess(response.data);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
  
    /**
     * Update game
     * */
    this.updateGame = (payload, onSuccess = () => {}, onError = () => {}) => {
      GlobalStore.setLoadingState(true);
      updateGameFunc(payload).then((response) => {
        if (response.data.code) {
          if (response.data.code === ERROR_CODES.PERMISSION_DENIED) {
            UserApi.logout();
          }
          const msg = response.data.message || MESSAGES.INTERNAL_ERROR;
          ToastStore.setCurrentMsg(msg, true);
        } else {
          ToastStore.setCurrentMsg(MESSAGES.UPDATE_SUCCESS);
          ServerTime.setTime(response.data.serverTime);
          onSuccess(response.data);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    };
  
    /**
     * Delete game
     * */
    this.deleteGame = (payload, onSuccess = () => {}, onError = () => {}) => {
      GlobalStore.setLoadingState(true);
      deleteGameFunc(payload).then((response) => {
        if (response.data.code) {
          if (response.data.code === ERROR_CODES.PERMISSION_DENIED) {
            UserApi.logout();
          }
          const msg = response.data.message || MESSAGES.INTERNAL_ERROR;
          ToastStore.setCurrentMsg(msg, true);
        } else {
          ToastStore.setCurrentMsg(MESSAGES.DELETE_GAME_SUCCESS);
          onSuccess(response.data);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      });
    }
  }
}

export default new GameAPI();