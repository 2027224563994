import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

const Toast = observer(({ toastStore }) => {
  let css = 'toast-container';
  if (toastStore.currentMsg) {
    css += ' show';
  }
  if (toastStore.isError) {
    css += ' error';
  }
  
  useEffect(() => {
    if (toastStore.currentMsg) {
      setTimeout(() => {
        toastStore.setCurrentMsg(null);
      }, 5000);
    }
  });
  
  return (
    <div className={ css }>
      <p>{ toastStore.currentMsg }</p>
    </div>
  );
});

export default Toast;