import { makeAutoObservable, toJS} from 'mobx';

class ToastStore {
  currentMsg = null;
  isError = false;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setCurrentMsg = (msg, error = false) => {
    this.currentMsg = msg;
    this.isError = error;
  };
}

export default new ToastStore();