import React, { useState } from 'react';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { URLS } from '../../conf/MainConf';
import TopBar from './TopBar';
import PageTitle from './PageTitle';

const PageWrapper = observer(({ userStore, title, children, onBack, create }) => {
  const location = useLocation();
  const [ lastLocation, setLastLocation ] = useState(location.pathname);
  const history = useHistory();
  const hasValidUser = userStore.userData && toJS(userStore.userData).email !== null;
  
  // CTA
  const onCreateGame = () => {
    history.push(URLS.GAME_CREATE);
  };
  const onLogOut = () => {
    setLastLocation(URLS.GAMES_LIST);
  };
  
  return (
    <div className="main-content">
      {
        hasValidUser && (
          <>
            <TopBar onCreateGame={ onCreateGame } onLogOut={ onLogOut } create={ create } onBack={ onBack } />
            { title && <PageTitle title={ title } /> }
            { children }
          </>
        )
      }
      { !hasValidUser && (<Redirect to={ {pathname: URLS.LOGIN, state: {from: lastLocation } } } />) }
    </div>
  );
});

export default PageWrapper;