import React from 'react';

const PageTitle = ({ title, onBack }) => {
  return (
    <header>
      <h1>{ title }</h1>
      { onBack && <button className="back-button" onClick={ onBack }>BACK</button> }
    </header>
  );
};

export default PageTitle;