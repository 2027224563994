import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import {GAMES, URLS} from '../conf/MainConf';
import { MESSAGES } from '../conf/MainConf';
import Utils from '../helpers/Utils';
import GameAPI from '../services/GameAPI';

const GameList = () => {
  const [gameList, setGameList] = useState([]);
  const [error, setError] = useState(null);
  const history = useHistory();
  
  const ctaHandler = (url) => {
    history.push(url);
  };
  
  // on mounted, load all games
  useEffect(() => {
    GameAPI.getGames((response) => {
      const allGames = Object.keys(response).map((key) => {
        const mmtDate = Utils.parseToMomentDate(response[key].date);
        const gameStatus = Utils.getGameStatus(response[key].date);
        console.log(response[key].name, ', --> ', gameStatus);
        return {
          gameCode: key,
          name: response[key].name,
          date: mmtDate,
          isEditable: gameStatus !== GAMES.GAME_STATUS.ENDED && gameStatus !== GAMES.GAME_STATUS.STARTED,
          dateUpdate: response[key].dateUpdate
        }
      });
      if (allGames.length > 0) {
        setGameList(_.sortBy(allGames, ['dateUpdate']).reverse());
      }
    }, (errorObj) => {
      setError(MESSAGES.INTERNAL_ERROR);
    })
  }, []);
  
  return (
    <div className="inner-content game-list-container">
      {
        gameList.length > 0 && (
          <table>
            <tbody>
            {
              gameList.map((gameObj) => {
                return (
                  <tr key={ uuidv4() }>
                    <td className="col-date">
                      <span className="date">{ gameObj.date.format('YYYY-MM-DD') }</span>
                      <span className="hour">{ gameObj.date.format('hh:mm A (ZZ)') }</span>
                    </td>
                    <td className="col-name">{ gameObj.name }</td>
                    <td className="col-cta">
                      <button
                        className="seamless-button"
                        onClick={ () => { ctaHandler(`${URLS.GAME_VIEW.replace(':id', gameObj.gameCode)}`); } }
                      >
                        SEE
                      </button>
                      <span className={ !gameObj.isEditable ? 'btn-disabled' : '' }> - </span>
                      <button
                        className={ !gameObj.isEditable ? 'seamless-button btn-disabled' : 'seamless-button' }
                        onClick={ () => { ctaHandler(`${URLS.GAME_EDIT.replace(':id', gameObj.gameCode)}`); } }
                        disabled={ !gameObj.isEditable }
                      >
                        EDIT
                      </button>
                      <span className={ !gameObj.isEditable ? 'btn-disabled' : '' }> - </span>
                      <button
                        className="seamless-button"
                        onClick={ () => { ctaHandler(`${URLS.GAME_DELETE.replace(':id', gameObj.gameCode)}`); } }
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        )
      }
      { error && <p className="error-msg">{ error }</p> }
    </div>
  );
};

export default GameList;