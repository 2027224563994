import React, { useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useFormFields } from '../helpers/Hooks';
import UserApi from '../services/UserApi';
import { ERROR_CODES, MESSAGES } from '../conf/MainConf';

const Login = () => {
  const [fieldsValue, setFieldValue] = useFormFields({ email: '', password: '' });
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useLocation();
  const history = useHistory();
  
  const onSubmitForm = () => {
    setErrorMsg(null);
    UserApi.login(fieldsValue, () => {
      history.replace(location.state.from || '/');
    }, (errorObj) => {
      if (errorObj) {
        console.log('errorObj.code -> ', errorObj.code);
        switch (errorObj.code) {
          case ERROR_CODES.WRONG_LOGIN:
            setErrorMsg(MESSAGES.WRONG_LOGIN);
            break;
          case ERROR_CODES.WRONG_EMAIL:
            setErrorMsg(MESSAGES.WRONG_EMAIL);
            break;
          case ERROR_CODES.WRONG_PASSWORD:
            setErrorMsg(MESSAGES.WRONG_PASSWORD);
            break;
          default:
            setErrorMsg(MESSAGES.INTERNAL_ERROR);
        }
      }
    });
  };
  return (
    <div className="login-container">
      <div className="inner-wrapper">
        <table>
          <tbody>
            <tr>
              <td className="label">
                LOGIN :
              </td>
              <td>
                <input type="text" name="email" value={ fieldsValue.email } onChange={ setFieldValue }/>
              </td>
            </tr>
            <tr>
              <td className="label">
                PASSWORD :
              </td>
              <td>
                <input type="password" name="password" value={ fieldsValue.password } onChange={ setFieldValue }/>
              </td>
            </tr>
            <tr>
              <td className="label" />
              <td className="submit">
                <button type="submit" onClick={ onSubmitForm } className={ !fieldsValue.password || !fieldsValue.email ? 'disabled' : '' } disabled={ !fieldsValue.password || !fieldsValue.email }>OK</button>
              </td>
            </tr>
          </tbody>
        </table>
        { errorMsg && <p className="error-msg">{ errorMsg }</p>}
      </div>
    </div>
  );
};

export default Login;
