import React from 'react';

const ModalMessage = ({ message }) => {
  const createMarkup = (htmlStr) => {
    return {__html: htmlStr };
  };
  
  return (
    <div className="modal-message-wrapper">
      <p dangerouslySetInnerHTML={ createMarkup(message) } />
    </div>
  )
};

export default ModalMessage;