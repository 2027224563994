import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  
  return [
    fields,
    (event) => {
      if (event && event.target && event.target.name) {
        setValues({
          ...fields,
          [event.target.name]: event.target.value
        });
      }
    }
  ];
}