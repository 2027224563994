import { makeAutoObservable, toJS} from 'mobx';

class UserStore {
  userData = null;
  
  constructor() {
    makeAutoObservable(this);
  }
  
  setUserData = (obj) => {
    this.userData = obj;
  }
}

export default new UserStore();