import React from 'react';

const LoaderSpinner = () => {
  return (
    <div className="load-spinner-container">
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};

export default LoaderSpinner;