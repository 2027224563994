import { FirebaseAuth } from './FirebaseAPI';
import GlobalStore from '../stores/GlobalStore';
import UserStore from '../stores/UserStore';

class UserApi {
  constructor() {
    const updateUserStore = (payload) => {
      if (payload) {
        const { email, refreshToken, uid } = payload;
        UserStore.setUserData({ email, refreshToken, uid });
      } else {
        UserStore.setUserData(null);
      }
    };
    
    // Observe user auth
    this.observeAuthState = (onResult) => {
      FirebaseAuth.onAuthStateChanged((userData) => {
        updateUserStore(userData);
        onResult(userData);
      });
    };
    
    this.login = (payload, onSuccess = () => { }, onError = () => { }) => {
      GlobalStore.setLoadingState(true);
      FirebaseAuth.signInWithEmailAndPassword(payload.email, payload.password).then((response) => {
        if (response.user) {
          updateUserStore(response.user);
          onSuccess();
        } else {
          onError(response);
        }
      }).catch((errorObj) => {
        onError(errorObj);
      }).finally(() => {
        GlobalStore.setLoadingState(false);
      })
    };
    
    this.logout = async () => {
      GlobalStore.setLoadingState(true);
      await FirebaseAuth.signOut();
      GlobalStore.setLoadingState(false);
    }
  }
}

export default new UserApi();