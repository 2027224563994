export const FIREBASE_CONF = {
  BASE_CONF_DEV: {
    apiKey: "AIzaSyDCAjy8SU3VmwM8ohxVLlj6DkGsSnoM6g0",
    authDomain: "syl-lvmh-lab.firebaseapp.com",
    databaseURL: "https://syl-lvmh-lab.firebaseio.com",
    projectId: "syl-lvmh-lab",
    storageBucket: "syl-lvmh-lab.appspot.com",
    messagingSenderId: "246053053976",
    appId: "1:246053053976:web:393d99313af7eb728b856d",
    measurementId: "G-KH09B7DL7D"
  },
  BASE_CONF_PROD: {
    apiKey: "AIzaSyCiDIZAgvHD9t4cIZW4BlCTEgrj75VzQCg",
    authDomain: "tds-lvmhcomplianceescape.firebaseapp.com",
    databaseURL: "https://tds-lvmhcomplianceescape.firebaseio.com",
    projectId: "tds-lvmhcomplianceescape",
    storageBucket: "tds-lvmhcomplianceescape.appspot.com",
    messagingSenderId: "268524107351",
    appId: "1:268524107351:web:bfe3be40153eee1d855512",
    measurementId: "G-GBQKQ9RZB9"
  }
};

export const APP = {
  NAME: 'LVMH - ESCAPE GAME - ADMIN'
};

// GAMES status : /* -1: before start-up, 0: start-up, 1: started, 2: ended */
export const GAMES = {
  MAX_TEAM: 5,
  MAX_GUEST: 5,
  GAME_DURATION: 45, // mn
  GAME_WAIT_DURATION: 60, // mn
  HOUR_FORMAT: 'hh:mm A',
  DATE_FORMAT: 'YYYY/DD/MM',
  DATE_RFC_FORMAT: 'ddd, DD MMM YYYY HH:mm:ss',
  GAME_STATUS: {
    COMING: -1,
    TO_START: 0,
    STARTED: 1,
    ENDED: 2
  }
};

export const PAGES = {
  LOGIN: {
    id: 0,
    name: 'login',
    title: 'login'
  },
  GAMES_LIST: {
    id: 1,
    name: 'games-list',
    title: 'Game list'
  },
  GAME_CREATE: {
    id: 2,
    name: 'new-game',
    title: 'New Game'
  },
  GAME_VIEW: {
    id: 3,
    name: 'view-game',
    title: 'Game'
  },
  GAME_EDIT: {
    id: 4,
    name: 'edit-game',
    title: 'Edit Game'
  },
  GAME_DELETE: {
    id: 5,
    name: 'delete-game',
    title: 'Game'
  }
};

export const ERROR_CODES = {
  PERMISSION_DENIED: 'permission-denied',
  WRONG_LOGIN: 'auth/user-not-found',
  WRONG_EMAIL: 'auth/invalid-email',
  WRONG_PASSWORD: 'auth/wrong-password',
};

export const MESSAGES = {
  WRONG_LOGIN: 'User not found',
  WRONG_EMAIL: 'Invalid email',
  WRONG_PASSWORD: 'Wrong password',
  INTERNAL_ERROR: 'Internal error. Please try again later',
  FORM_INVALID_EMAIL: 'correct invalid emails',
  FORM_INVALID_DATE: 'set a future date',
  FORM_REQUIRED: 'fill mandatory fields',
  FORM_UNIQ_TEAM_NAME: 'Team name must be unique',
  UPDATE_SUCCESS: 'data successfully updated!',
  DELETE_GAME_SUCCESS: 'game deleted!',
  DELETE: 'Are you sure you want <br /> to delete this game ?'
};

export const URLS = {
  LOGIN: '/login',
  GAMES_LIST: '/',
  GAME_CREATE: '/game',
  GAME_VIEW: '/game/:id',
  GAME_EDIT: '/game/edit/:id',
  GAME_DELETE: '/game/delete/:id',
};